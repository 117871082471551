import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'

import navEN from './locales/en/nav.json'
import homeEN from './locales/en/home.json'
import aboutEN from './locales/en/about.json'

import navCN from './locales/cn/nav.json'
import homeCN from './locales/cn/home.json'
import aboutCN from './locales/cn/about.json'

// translations are already at
// '../public/locales/en/nav.json'
// which is the default for the xhr backend to load from

const resources = {
  en: {
    nav: navEN,
    home: homeEN,
    about: aboutEN,
  },

  cn: {
    nav: navCN,
    home: homeCN,
    about: aboutCN,
  },
}

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .use(detector)
  .init({
    resources,
    debug: true,
    fallbackLng: 'en', // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
