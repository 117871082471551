import React from 'react'
import Style from './About.module.scss'
import { Box } from '@mui/material'
import { info } from '../../info/Info'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import SchoolIcon from '@material-ui/icons/School'
import WorkIcon from '@material-ui/icons/Work'
import StarIcon from '@material-ui/icons/Star'
import { withNamespaces } from 'react-i18next'

function About({ t }) {
  const firstName = info.firstName.toLowerCase()

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
      {/*<Terminal text={miscText()}/>*/}
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={t('amazonSDMJobTime')}
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">{t('amazonSDMJobTitle')}</h3>
          <h4 className="vertical-timeline-element-subtitle">{t('amazonSDMCompany')}</h4>

          <p>{t('amazonSDMJobDescription')}</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={t('amazonJobTime')}
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">{t('amazonJobTitle')}</h3>
          <h4 className="vertical-timeline-element-subtitle">{t('amazonCompany')}</h4>

          <p>{t('amazonJobDescription')}</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date={t('degreeDate')}
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">{t('degree')}</h3>
          <h4 className="vertical-timeline-element-subtitle">{t('school')}</h4>
          <p>{t('specializations')}</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={t('kernelJobTime')}
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">{t('kernelJobTitle')}</h3>
          <h4 className="vertical-timeline-element-subtitle">{t('kernelCompany')}</h4>
          <p>{t('kernelJobDescription')}</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={t('tutorCompany')}
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">{t('tutorJobTitle')}</h3>
          <h4 className="vertical-timeline-element-subtitle">{t('tutorCompany')}</h4>
          <p>{t('tutorJobDescription')}</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }} icon={<StarIcon />} />
      </VerticalTimeline>
    </Box>
  )
}

export default withNamespaces('about')(About)
