import React, { useState } from 'react'
import Style from './Navbar.module.scss'
import Toggler from './home/Toggler'
import { Link, useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import { info } from '../info/Info'
import { withNamespaces } from 'react-i18next'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { IoLanguageSharp } from 'react-icons/io5'
import i18n from 'i18next'

const ROUTE_HOME = '/'
const ROUTE_ABOUT = '/about'
const ROUTE_RESUME = 'https://drive.google.com/file/d/1n_CpsHduixNK3ex5PVls_RsHfFbDVu1o/view'

const PAGE_HOME = 'home'
const PAGE_ABOUT = 'about'

function Navbar({ darkMode, handleClick, t }) {
  const location = useLocation()
  const [active, setActive] = useState(location.pathname === ROUTE_HOME ? PAGE_HOME : location.pathname.slice(1, location.pathname.length))

  const changeLanguage = () => {
    const currentLng = i18n.language
    if (currentLng === 'en') {
      i18n.changeLanguage('cn')
    } else {
      i18n.changeLanguage('en')
    }
  }

  return (
    <Box component={'nav'} width={'100%'}>
      <Box
        component={'ul'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={{ xs: '2rem', md: '8rem' }}
        fontSize={'1rem'}
      >
        <Box component={'li'} className={active === 'home' && Style.active} sx={{ borderImageSource: info.gradient }}>
          <Link to={ROUTE_HOME} onClick={() => setActive(PAGE_HOME)}>
            <p style={{ paddingBottom: '0.5rem' }}>{t('home')}</p>
          </Link>
        </Box>

        <Box component={'li'} className={active === 'about' && Style.active} sx={{ borderImageSource: info.gradient }}>
          <Link to={ROUTE_ABOUT} onClick={() => setActive(PAGE_ABOUT)}>
            <p style={{ paddingBottom: '0.5rem' }}>{t('about')}</p>
          </Link>
        </Box>

        <Box component={'li'} sx={{ borderImageSource: info.gradient }}>
          <Link to={ROUTE_HOME} onClick={() => setActive(ROUTE_HOME)}>
            <h1>ky</h1>
          </Link>
        </Box>

        <Box>
          <a href={ROUTE_RESUME} target="_blank">
            <p style={{ paddingBottom: '0.5rem' }}>
              {t('resume')} <FaExternalLinkAlt />
            </p>
          </a>
        </Box>

        <Box>
          <p style={{ paddingBottom: '0.5rem', cursor: 'pointer' }} onClick={() => changeLanguage()}>
            {t('language')} <IoLanguageSharp />
          </p>
        </Box>

        {/*TODO: Disabled until everything else worked with darkmode*/}
        {/*<li>*/}
        {/*    <Toggler darkMode={darkMode} handleClick={handleClick}/>*/}
        {/*</li>*/}
      </Box>
    </Box>
  )
}

export default withNamespaces('nav')(Navbar)
